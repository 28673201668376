import type * as Polymorphic from '@radix-ui/react-polymorphic';
import type { HTMLAttributes, PropsWithChildren } from 'react';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { sizing } from '../../common/sizing';
import { selectors } from '../../controls/shared/styles';
import {
  colors,
  css,
  darkThemeSelector,
  fonts,
  fontWeights,
  shadows,
  styled,
} from '../../stitches.config';
import { Text } from '../../text/Text';
import { space } from '../../utilities/shared/sizes';
import { Tooltip } from '../Tooltip/Tooltip';
import { useCanScrollX } from './useCanScrollX';

export function TableCellEmpty({ internal }: { internal?: boolean }) {
  return (
    <Text family="monospace" internal={internal} style={{ color: 'currentColor' }}>
      -
    </Text>
  );
}

const rowHeight = 36;

const TableCellCollapsable = styled(Icon, {
  width: '$12',
  height: '$12',
});

const TableCellContents = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '$6 $12',
  width: '100%',
  minHeight: `${rowHeight}px`,
  color: '$$textColor',
  fontFamily: fonts.sans,
  fontWeight: fontWeights.regular,
  fontSize: '$14',
  lineHeight: '$16',
  fontVariant: 'tabular-nums',
  verticalAlign: 'middle',
  wordBreak: 'keep-all',
  whiteSpace: 'nowrap',
  cursor: 'default',

  [selectors.focus]: {
    zIndex: 3,
    outline: 'none',
  },

  variants: {
    alignment: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
    hasNested: {
      true: {
        padding: '$4',
        background: colors.bgNeutralLight,
        strokeAll: colors.strokeNeutralLight,
        borderRadius: '$10',

        [darkThemeSelector]: {
          background: colors.bgNeutralDark,
          strokeAll: colors.strokeNeutralDark,
        },

        [selectors.focus]: {
          '&::after': {
            display: 'none',
          },
        },
      },
      false: {},
    },
    head: {
      true: {
        minHeight: 'auto',
      },
      false: {},
    },
    isLeading: {
      true: {
        color: '$$leadingTextColor',
        fontWeight: fontWeights.bold,
        background: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
        },

        [selectors.hover]: {
          color: colors.linkHoverLight,

          [darkThemeSelector]: {
            color: colors.linkHoverDark,
          },
        },
      },
      false: {},
    },
    isNavigable: {
      true: {
        cursor: 'pointer',

        [selectors.focus]: {
          '&::before': {
            content: '',
            position: 'absolute',
            inset: '$4',
            display: 'block',
            boxShadow: shadows.focusRingLight,
            borderRadius: '$8',

            [darkThemeSelector]: {
              boxShadow: shadows.focusRingDark,
            },
          },
        },
      },
      false: {},
    },
    isNested: {
      true: {},
      false: {},
    },
    internal: {
      true: {
        color: '$$internalBodyLight',

        [darkThemeSelector]: {
          color: '$$internalBodyDark',
        },
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      isLeading: true,
      isNested: true,
      css: {
        background: colors.bgNeutralLight,

        [darkThemeSelector]: {
          background: colors.bgNeutralDark,
        },
      },
    },
  ],
});

const TableCellContainer = styled('td', {
  padding: 0,

  variants: {
    collapsable: {
      true: {
        width: '$36',
      },
      false: {},
    },
    condense: {
      true: {
        '@maxSm': {
          display: 'none',
        },
      },
      false: {},
    },
    hasNested: {
      true: {
        padding: `0 $16`,
      },
      false: {},
    },
    isLeading: {
      true: {
        position: 'sticky',
        left: 0,
        zIndex: 5,
      },
      false: {},
    },
    stuck: {
      top: {
        position: 'sticky',
        top: 0,
        zIndex: 6,
      },
      right: {
        position: 'sticky',
        right: 0,
        zIndex: 6,
      },
      bottom: {
        position: 'sticky',
        bottom: 0,
        zIndex: 6,
      },
      left: {
        position: 'sticky',
        left: 0,
        zIndex: 6,
      },
    },
  },
});

interface TableCellProps {
  /**
   * The alignment of the cell content.
   */
  alignment?: 'start' | 'center' | 'end';
  /**
   * Whether the cell should be collapsable.
   */
  collapsable?: boolean;
  /**
   * Whether the cell is collapsed.
   */
  collapsed?: boolean;
  /**
   * The number of columns the cell should span.
   */
  colSpan?: number;
  /**
   * Whether the cell should be hidden to tablet and mobile users.
   */
  condense?: boolean;
  /**
   * Whether the cell has a nested `Table`.
   */
  hasNested?: boolean;
  /**
   * Whether the cell is the leading cell in the row.
   */
  isLeading?: boolean;
  /**
   * Whether the cell is part of a nested `Table`.
   */
  isNested?: boolean;
  /**
   * Whether the cell is navigable and should show it can be clicked/tapped.
   */
  isNavigable?: boolean;
  /**
   * Whether the cell is stuck to the top, right, bottom, or left of the `Table`.
   */
  stuck?: 'top' | 'right' | 'bottom' | 'left';
  /**
   * Whether the cell is visible only to Meter employees.
   */
  internal?: boolean;
}

function TableCellPassthrough<Tag extends React.ElementType>(
  {
    as,
    alignment,
    children,
    collapsed,
    collapsable,
    colSpan,
    condense,
    hasNested,
    isLeading,
    isNavigable,
    isNested,
    stuck,
    to,
    ...props
  }: PolymorphicComponentProps<Tag, TableCellProps>,
  ref: PolymorphicRef<Tag>,
) {
  return (
    <TableCellContainer
      collapsable={collapsable}
      colSpan={colSpan}
      condense={condense}
      hasNested={hasNested}
      isLeading={isLeading}
      stuck={stuck}
    >
      <TableCellContents
        as={collapsable ? 'button' : as}
        to={to}
        alignment={alignment}
        hasNested={hasNested}
        isLeading={isLeading}
        isNavigable={!!to || isNavigable || collapsable}
        isNested={isNested}
        tabIndex={collapsable || (isLeading && to) ? 0 : undefined}
        data-has-nested={hasNested && 'true'}
        {...props}
        ref={ref}
      >
        {collapsable && (
          <TableCellCollapsable icon={collapsed ? 'chevron-right' : 'chevron-down'} />
        )}
        {children}
      </TableCellContents>
    </TableCellContainer>
  );
}

export const TableCell = React.forwardRef(TableCellPassthrough) as Polymorphic.ForwardRefComponent<
  React.ElementType,
  TableCellProps
>;

const stateCellWidth = 8;
const bufferCellWidth = sizing.primary - stateCellWidth;

export const TableCellBuffer = styled('td', {
  width: `${bufferCellWidth}px`,
  minWidth: `${bufferCellWidth}px`,
  maxWidth: `${bufferCellWidth}px`,

  variants: {
    head: {
      true: {
        position: 'sticky',
        top: 0,
        background: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
        },
      },
      false: {},
    },
  },
});

const TableCellStateContents = styled('div', {
  width: '100%',
  minHeight: `${rowHeight}px`,

  variants: {
    head: {
      true: {
        minHeight: 'auto',
      },
      false: {},
    },
  },
});

const TableCellStateContainer = styled('td', {
  padding: 0,
  width: `${stateCellWidth}px`,
  minWidth: `${stateCellWidth}px`,
  maxWidth: `${stateCellWidth}px`,

  variants: {
    head: {
      true: {
        position: 'sticky',
        top: 0,
        background: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
        },
      },
      false: {},
    },
  },
});

type TableCellStateProps = {
  /**
   * Whether the cell state is at the top of the table.
   */
  head?: boolean;
};

function TableCellState({ head, ...remaining }: TableCellStateProps) {
  return (
    <TableCellStateContainer head={head} {...remaining}>
      <TableCellStateContents head={head} />
    </TableCellStateContainer>
  );
}
export const TableCellStateLeading = styled(TableCellState, {
  [`& ${TableCellStateContents}`]: {
    borderRadius: '$10 0 0 $10',
  },
});
export const TableCellStateTrailing = styled(TableCellState, {
  [`& ${TableCellStateContents}`]: {
    borderRadius: '0 $10 $10 0',
  },
});

const TableRowStyles = css({
  $$iconColor: colors.iconNeutralLight,
  $$textColor: colors.bodyNeutralLight,
  $$leadingTextColor: colors.linkInitialLight,

  $$internalBodyLight: colors.internalBodyLight,
  $$internalBodyDark: colors.internalBodyDark,

  position: 'relative',
  width: '100%',

  [darkThemeSelector]: {
    $$iconColor: colors.iconNeutralDark,
    $$textColor: colors.bodyNeutralDark,
    $$leadingTextColor: colors.linkInitialDark,
  },

  [`&:not(:last-child) > ${TableCellContainer} > ${TableCellContents}:not([data-has-nested="true"])`]:
    {
      strokeTopBottom: colors.strokeNeutralLight,

      [darkThemeSelector]: {
        strokeTopBottom: colors.strokeNeutralDark,
      },
    },

  variants: {
    isSelected: {
      true: {
        zIndex: 2,
        $$iconColor: colors.bodyBrandLight,
        $$textColor: colors.headingBrandLight,
        $$leadingTextColor: colors.headingBrandLight,

        [darkThemeSelector]: {
          $$iconColor: colors.bodyBrandDark,
          $$textColor: colors.headingBrandDark,
          $$leadingTextColor: colors.headingBrandDark,
        },

        [`& > ${TableCellContainer} > ${TableCellContents}:not([data-has-nested="true"])`]: {
          background: colors.bgBrandLight,
          strokeTopBottom: colors.strokeBrandLight,

          [darkThemeSelector]: {
            background: colors.bgBrandDark,
            strokeTopBottom: colors.strokeBrandDark,
          },
        },

        [`& > ${TableCellStateContainer} > ${TableCellStateContents}`]: {
          background: colors.bgBrandLight,

          [darkThemeSelector]: {
            background: colors.bgBrandDark,
          },
        },

        [`& > ${TableCellStateLeading} > ${TableCellStateContents}`]: {
          strokeNoRight: colors.strokeBrandLight,

          [darkThemeSelector]: {
            strokeNoRight: colors.strokeBrandDark,
          },
        },

        [`& > ${TableCellStateTrailing} > ${TableCellStateContents}`]: {
          strokeNoLeft: colors.strokeBrandLight,

          [darkThemeSelector]: {
            strokeNoLeft: colors.strokeBrandDark,
          },
        },

        [selectors.hover]: {
          [`& > ${TableCellContainer} > ${TableCellContents}:not([data-has-nested="true"])`]: {
            $$leadingTextColor: colors.headingBrandLight,
            background: colors.bgBrandLight,
            strokeTopBottom: colors.strokeBrandLight,

            [darkThemeSelector]: {
              $$leadingTextColor: colors.headingBrandDark,
              background: colors.bgBrandDark,
              strokeTopBottom: colors.strokeBrandDark,
            },
          },

          [`& > ${TableCellStateContainer} > ${TableCellStateContents}`]: {
            background: colors.bgBrandLight,

            [darkThemeSelector]: {
              background: colors.bgBrandDark,
            },
          },

          [`& > ${TableCellStateLeading} > ${TableCellStateContents}`]: {
            strokeNoRight: colors.strokeBrandLight,

            [darkThemeSelector]: {
              strokeNoRight: colors.strokeBrandDark,
            },
          },

          [`& > ${TableCellStateTrailing} > ${TableCellStateContents}`]: {
            strokeNoLeft: colors.strokeBrandLight,

            [darkThemeSelector]: {
              strokeNoLeft: colors.strokeBrandDark,
            },
          },
        },
      },
      false: {},
    },
  },
});

const TableRowContainer = styled('tr', TableRowStyles, {
  [selectors.hover]: {
    zIndex: 1,

    [`& > ${TableCellContainer} > ${TableCellContents}:not([data-has-nested="true"])`]: {
      background: colors.bgNeutralLight,
      strokeTopBottom: colors.strokeNeutralLight,

      [darkThemeSelector]: {
        background: colors.bgNeutralDark,
        strokeTopBottom: colors.strokeNeutralDark,
      },
    },

    [`& > ${TableCellStateContainer} > ${TableCellStateContents}`]: {
      background: colors.bgNeutralLight,

      [darkThemeSelector]: {
        background: colors.bgNeutralDark,
      },
    },

    [`& > ${TableCellStateLeading} > ${TableCellStateContents}`]: {
      strokeNoRight: colors.strokeNeutralLight,

      [darkThemeSelector]: {
        strokeNoRight: colors.strokeNeutralDark,
      },
    },

    [`& > ${TableCellStateTrailing} > ${TableCellStateContents}`]: {
      strokeNoLeft: colors.strokeNeutralLight,

      [darkThemeSelector]: {
        strokeNoLeft: colors.strokeNeutralDark,
      },
    },
  },
});

interface TableRowProps {
  /**
   * The content of the row.
   */
  children?: React.ReactNode | undefined;
  /**
   * Whether the row is navigable and should show it can be clicked/tapped.
   */
  isNavigable?: boolean;
  /**
   * Whether the row is selected.
   */
  isSelected?: boolean;
}

type RowSelected = boolean;
const RowSelectedContext = createContext<RowSelected | undefined>(undefined);
export const RowSelectedProvider = RowSelectedContext.Provider;
export const useRowSelected = (
  controlledValue?: RowSelected,
  defaultValue: RowSelected = false,
) => {
  const rowSelected = useContext(RowSelectedContext);
  return controlledValue ?? rowSelected ?? defaultValue;
};

function TableRowPassthrough<Tag extends React.ElementType>(
  { isSelected, ...props }: PolymorphicComponentProps<Tag, TableRowProps>,
  ref: PolymorphicRef<Tag>,
) {
  return (
    <TableRowContainer
      ref={ref}
      {...props}
      isSelected={isSelected}
      data-is-selected={isSelected && 'true'}
    >
      <RowSelectedProvider value={isSelected}>{props.children}</RowSelectedProvider>
    </TableRowContainer>
  );
}

export const TableRow = React.forwardRef(TableRowPassthrough) as Polymorphic.ForwardRefComponent<
  React.ElementType,
  TableRowProps
>;

export const TableBody = styled('tbody');

const TableHeadCellContainer = styled('td', {
  position: 'sticky',
  top: 0,
  padding: '$6 $12',
  backgroundColor: colors.bgApplicationLight,
  strokeBottom: colors.strokeNeutralLight,
  cursor: 'pointer',
  fontWeight: fontWeights.bold,
  fontFamily: fonts.sans,
  fontSize: '$12',
  lineHeight: '$16',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
    strokeBottom: colors.strokeNeutralDark,
  },

  variants: {
    collapsable: {
      true: {
        width: '$36',
      },
      false: {},
    },
    internal: {
      true: {
        color: '$$internalBodyLight',

        [darkThemeSelector]: {
          color: '$$internalBodyDark',
        },
      },
      false: {},
    },
    isLeading: {
      true: {
        position: 'sticky',
        left: 0,
        zIndex: 12,
        background: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
        },
      },
      false: {},
    },
    isSorted: {
      true: {
        zIndex: 11,
      },
      false: {
        zIndex: 10,
      },
    },
    stuck: {
      top: {
        position: 'sticky',
        top: 0,
        zIndex: 12,
      },
      right: {
        position: 'sticky',
        right: 0,
        zIndex: 12,
      },
      bottom: {
        position: 'sticky',
        bottom: 0,
        zIndex: 12,
      },
      left: {
        position: 'sticky',
        left: 0,
        zIndex: 12,
      },
    },
  },
});

const TableHeadCellSort = styled('div', {
  position: 'relative',
  width: 0,
  height: '$10',

  variants: {
    isVisible: {
      true: {
        // TRICKY: Toggle visibility rather than display to always reserve
        // space in the layout for the icon.
        visibility: 'visible',
      },
      false: {
        visibility: 'hidden',
      },
    },
  },
  defaultVariants: {
    isVisible: false,
  },
});

const TableHeadCellIcon = styled(Icon, {
  width: '$14',
  height: '$14',
  color: colors.iconNeutralLight,

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },
});

const TableHeadCellSortIcon = styled(Icon, {
  display: 'flex',
  marginLeft: '$4',
});

const TableHeadCellContents = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '100%',

  variants: {
    alignment: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
    canSort: {
      true: {
        outline: 'none',
        cursor: 'pointer',

        [selectors.focus]: {
          '&::before': {
            content: '',
            position: 'absolute',
            inset: '$4',
            display: 'block',
            boxShadow: shadows.focusRingLight,
            borderRadius: '$8',

            [darkThemeSelector]: {
              boxShadow: shadows.focusRingDark,
            },
          },
        },
      },
      false: {
        cursor: 'default',
      },
    },
  },
});

type TableHeadCellProps = PropsWithChildren<
  HTMLAttributes<HTMLTableHeaderCellElement> & {
    /**
     * The alignment of the cell content.
     */
    alignment?: 'start' | 'center' | 'end';
    /**
     * Whether the cell is collapsable.
     */
    collapsable?: boolean;
    /**
     * Whether the cell is collapsed.
     */
    collapsed?: boolean;
    /**
     * Whether to hide the sort icon.
     */
    hideSortIcon?: boolean;
    /**
     * The icon to display in the cell, label is hidden.
     */
    icon?: IconName;
    /**
     * Whether the cell is leading.
     */
    isLeading?: boolean;
    /**
     * Whether the cell is visible only to Meter employees.
     */
    internal?: boolean;
    /**
     * The sort direction of the cell.
     */
    sortDirection?: 'asc' | 'desc' | boolean;
    /**
     * Whether the cell is stuck to the top, right, bottom, or left of the `Table`.
     */
    stuck?: 'top' | 'right' | 'bottom' | 'left';
    /**
     * The tooltip contents to display when the cell's label is hovered.
     */
    tooltip?: React.ReactNode;
  }
>;

function TableHeadCellInner(
  {
    alignment,
    children,
    collapsable,
    collapsed,
    hideSortIcon,
    icon,
    isLeading,
    sortDirection,
    stuck,
    tooltip,
    ...props
  }: TableHeadCellProps,
  ref: React.ForwardedRef<HTMLTableHeaderCellElement>,
) {
  const tableHeadCellLabel = icon ? <TableHeadCellIcon icon={icon} /> : children;
  return (
    <TableHeadCellContainer
      isLeading={isLeading}
      isSorted={!!sortDirection}
      stuck={stuck}
      {...props}
      ref={ref}
    >
      <TableHeadCellContents
        as={collapsable ? 'button' : 'div'}
        canSort={!hideSortIcon || !!sortDirection}
        tabIndex={collapsable || !hideSortIcon ? 0 : undefined}
        alignment={alignment}
      >
        {collapsable && (
          <TableCellCollapsable icon={collapsed ? 'chevron-right' : 'chevron-down'} />
        )}
        {tooltip ? (
          <Tooltip asChild={false} contents={tooltip}>
            {tableHeadCellLabel}
          </Tooltip>
        ) : (
          tableHeadCellLabel
        )}
        {!collapsable && (!hideSortIcon || !!sortDirection) && (
          <TableHeadCellSort isVisible={!!sortDirection}>
            <TableHeadCellSortIcon
              icon={sortDirection === 'asc' ? 'chevron-up' : 'chevron-down'}
              size={space(10)}
            />
          </TableHeadCellSort>
        )}
      </TableHeadCellContents>
    </TableHeadCellContainer>
  );
}

export const TableHeadCell = React.forwardRef<HTMLTableHeaderCellElement, TableHeadCellProps>(
  TableHeadCellInner,
);

const TableHeadRowContainer = styled('tr', {
  position: 'relative',
  zIndex: 10,
  color: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

type TableHeadRowProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

function TableHeadRowPassthrough(
  props: TableHeadRowProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const innerRef = useRef<HTMLDivElement>(null);
  const [isPinned, setIsPinned] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([e]) => setIsPinned(e.intersectionRatio < 1), {
      threshold: [1],
    });

    if (innerRef.current) {
      observer.observe(innerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <TableHeadRowContainer {...props} ref={mergeRefs([ref, innerRef])} data-is-pinned={isPinned} />
  );
}

export const TableHeadRow = React.forwardRef<HTMLDivElement, TableHeadCellProps>(
  TableHeadRowPassthrough,
);

export const TableHead = styled('thead');

const TableContainer = styled('table', {
  position: 'relative',
  width: '100%',
  background: colors.bgApplicationLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
  },

  variants: {
    isNested: {
      true: {
        background: colors.bgNeutralLight,

        [darkThemeSelector]: {
          background: colors.bgNeutralDark,
        },

        [`& ${TableHeadRowContainer}`]: {
          zIndex: 7,
        },

        [`& ${TableHeadCellContainer}, & ${TableHeadRowContainer} ${TableCellBuffer}, & ${TableCellStateContainer}`]:
          {
            background: colors.bgNeutralLight,

            [darkThemeSelector]: {
              background: colors.bgNeutralDark,
            },
          },

        [`& ${TableRowContainer}`]: {
          [selectors.hover]: {
            [`&:not([data-is-selected="true"]) ${TableCellContainer} ${TableCellContents}, &:not([data-is-selected="true"]) ${TableCellStateContainer} ${TableCellStateContents}`]:
              {
                background: colors.bgApplicationLight,

                [darkThemeSelector]: {
                  background: colors.bgApplicationDark,
                },
              },
          },
        },
      },
      false: {},
    },
  },
});

interface TableProps extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  /**
   * Whether the table is nested within a parent `Table`.
   */
  isNested?: boolean;
}

export const Table = React.forwardRef<HTMLDivElement, TableProps>(
  ({ children, isNested, ...props }, ref) => {
    const [scrollRef, canScrollX] = useCanScrollX();
    return (
      <TableContainer
        ref={mergeRefs([ref, scrollRef])}
        isNested={isNested}
        data-can-scroll-x={canScrollX}
        {...props}
      >
        {children}
      </TableContainer>
    );
  },
);
