import type { AriaTextFieldProps } from '@react-types/textfield';
import React, { type ForwardedRef, useRef } from 'react';
import { useTextField } from 'react-aria';

import type { ControlSize } from '../../controls/shared/types';
import type { RenameKeys } from '../../types/rename_keys';
import type { InputSharedProps } from '../BaseInput/BaseInput';
import { selectors } from '../../controls/shared/styles';
import { styled } from '../../stitches.config';
import { BaseInput } from '../BaseInput/BaseInput';

type RemappedAriaTextFieldProps = RenameKeys<AriaTextFieldProps, { isDisabled: 'disabled' }>;

export interface SearchInputProps extends InputSharedProps, RemappedAriaTextFieldProps {
  invalid?: boolean;
  controlSize?: ControlSize;
  scope?: 'full' | 'scoped';
}

const SearchInputField = styled(BaseInput, {
  width: '54px',
  maxWidth: '54px',
  minWidth: '54px',

  [selectors.focus]: {
    width: '180px',
    maxWidth: '180px',
    minWidth: '180px',
  },
});

export const SearchInput = React.forwardRef<HTMLLabelElement, SearchInputProps>((props, ref) => {
  const {
    invalid,
    prefix,
    scope,
    suffix,
    controlSize,
    disabled = false,
    width,
    maxWidth,
    minWidth,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const { inputProps } = useTextField({ ...props, isDisabled: disabled }, inputRef);

  return (
    <SearchInputField
      ref={ref}
      type="search"
      inputRef={inputRef}
      inputProps={inputProps}
      invalid={invalid}
      disabled={disabled}
      icon={scope === 'scoped' ? 'search-scoped' : 'search'}
      prefix={prefix}
      suffix={suffix}
      controlSize={controlSize}
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
    />
  );
}) as (
  props: SearchInputProps & { ref?: ForwardedRef<HTMLLabelElement> },
) => ReturnType<typeof SearchInputField>;
