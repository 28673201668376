import { ServiceIcon } from 'atto-svgs';
import React from 'react';

import { darkThemeSelector, shadows, styled } from '../../stitches.config';

const BaseSVG = styled('svg', {
  display: 'inline-block',
  flexGrow: 0,
  flexShrink: 0,
  width: '100%',
});

const LargeSVG = styled('svg', BaseSVG, {
  height: '42px',
});

const Container = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '3px',
  boxShadow: shadows.manufacturerLight,
  flexShrink: 0,

  [darkThemeSelector]: {
    boxShadow: shadows.manufacturerDark,
  },

  variants: {
    size: {
      small: {
        borderRadius: '5px',
        width: '$20',
        height: '$20',
      },
      large: {
        padding: '7,5px',
        borderRadius: '15px',
        width: '$56',
        height: '$56',
      },
    },
  },
});

export type ServicesIconName =
  | 'asana'
  | 'github'
  | 'jira'
  | 'greenhouse'
  | 'sentry'
  | 'onepassword'
  | 'zoom'
  | 'linear'
  | 'quip'
  | 'notion'
  | 'slack'
  | 'kisi'
  | 'google'
  | 'ramp'
  | 'vercel'
  | 'docusign';

export type IconMapping = {
  [key in ServicesIconName]: React.ComponentType<React.HTMLProps<SVGSVGElement>>;
};
export const icons: IconMapping = {
  asana: ServiceIcon.Asana,
  github: ServiceIcon.Github,
  jira: ServiceIcon.Jira,
  greenhouse: ServiceIcon.GreenHouse,
  google: ServiceIcon.Google,
  onepassword: ServiceIcon.OnePassword,
  zoom: ServiceIcon.Zoom,
  linear: ServiceIcon.Linear,
  quip: ServiceIcon.Quip,
  notion: ServiceIcon.Notion,
  slack: ServiceIcon.Slack,
  kisi: ServiceIcon.Kisi,
  ramp: ServiceIcon.Ramp,
  docusign: ServiceIcon.DocuSign,
  sentry: ServiceIcon.Sentry,
  vercel: ServiceIcon.Vercel,
};

export interface ServicesIconProps {
  icon: ServicesIconName;
  className?: string;

  /**
   * Sets the size of the component.
   */
  size?: 'large' | 'medium' | 'small';
}

export function ServicesIcon({ className, icon, size = 'small', ...remaining }: ServicesIconProps) {
  const NamedIcon = icons[icon];

  if (!NamedIcon) {
    // eslint-disable-next-line no-console
    console.error(`Missing icon named ${icon}`);
    return null;
  }
  if (size === 'medium') {
    return (
      <div>
        <BaseSVG as={NamedIcon} />
      </div>
    );
  }
  return (
    <Container className={className} size={size} {...remaining}>
      <LargeSVG as={NamedIcon} />
    </Container>
  );
}
