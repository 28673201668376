import type * as Polymorphic from '@radix-ui/react-polymorphic';
import React from 'react';

import type { HardwareIconPropHardware } from '../../assets/HardwareIcon/HardwareIcon';
import type { ManufacturerIconName } from '../../assets/ManufacturerIcon/ManufacturerIcon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { HardwareIcon } from '../../assets/HardwareIcon/HardwareIcon';
import { Icon } from '../../assets/Icon/Icon';
import { ManufacturerIcon } from '../../assets/ManufacturerIcon/ManufacturerIcon';
import { fade, palette } from '../../common/colors';
import { selectors, transitions } from '../../controls/shared/styles';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Text } from '../../text/Text';

export type DeviceTargetPropType =
  | HardwareIconPropHardware
  | ManufacturerIconName
  | 'location'
  | 'locations'
  | 'port-forward'
  | 'rack'
  | 'radius'
  | 'ssid'
  | 'virtual-device'
  | 'vlan'
  | 'wifi';
type DeviceTargetPropSize = 'large' | 'medium' | 'small';

export type DeviceTargetProps = {
  'aria-label': string;
  children: React.ReactNode;
  onClick?: (event: any) => void;
  size?: DeviceTargetPropSize;
  type?: DeviceTargetPropType;
  wrap?: boolean;
  isActive?: boolean;
};

const DeviceTargetIconHardware = styled(HardwareIcon, {
  position: 'relative',
  zIndex: 2,
});

const DeviceTargetIconManufacturer = styled(ManufacturerIcon, {
  position: 'relative',
  zIndex: 2,
});

const DeviceTargetIconResource = styled(Icon, {
  position: 'relative',
  zIndex: 2,
  color: 'currentColor',

  [darkThemeSelector]: {
    color: 'currentColor',
  },
});

const DeviceTargetLabel = styled(Text, {
  position: 'relative',
  zIndex: '2',
  display: 'flex',
  fontWeight: fontWeights.medium,

  variants: {
    size: {
      large: {
        hStack: '$6',
        '@maxSm': {
          fontSize: '$24',
          lineHeight: '$32',
        },
        '@sm': {
          fontSize: '$20',
          lineHeight: '$28',
        },
      },
      medium: {
        hStack: '$4',
        '@maxSm': {
          fontSize: '$16',
          lineHeight: '$24',
        },
        '@sm': {
          fontSize: '$14',
          lineHeight: '$20',
        },
      },
      small: {
        hStack: '$4',
        '@maxSm': {
          fontSize: '$14',
          lineHeight: '$20',
        },
        '@sm': {
          fontSize: '$12',
          lineHeight: '$16',
        },
      },
    },
    wrap: {
      true: {
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
      },
      false: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});

const DeviceTargetBoundary = styled('div', {
  position: 'absolute',
  zIndex: 1,
  transition: transitions.control,
});

const DeviceTargetContainer = styled('div', {
  position: 'relative',
  maxWidth: '100%',
  cursor: 'pointer',
  transition: transitions.control,

  [selectors.hover]: {
    [`${DeviceTargetBoundary}`]: {
      backgroundColor: fade(palette.tokenBgNeutralLight, 0.4),
      strokeAll: fade(palette.tokenStrokeNeutralLight, 0.5),

      [darkThemeSelector]: {
        backgroundColor: fade(palette.tokenBgNeutralDark, 0.3),
        strokeAll: fade(palette.tokenStrokeNeutralDark, 0.4),
      },
    },
  },

  [selectors.focus]: {
    outline: 'none',

    [`${DeviceTargetBoundary}`]: {
      backgroundColor: colors.bgBrandLight,
      boxShadow: shadows.focusRingLight,

      [darkThemeSelector]: {
        backgroundColor: colors.bgBrandDark,
        boxShadow: shadows.focusRingDark,
      },
    },
  },

  variants: {
    size: {
      large: {
        hStack: '$6',
        display: 'inline-flex',

        [`${DeviceTargetBoundary}`]: {
          borderRadius: '10px',
          top: '-4px',
          right: '-12px',
          bottom: '-4px',
          left: '-12px',
        },
      },
      medium: {
        hStack: '$4',
        display: 'inline-flex',

        [`${DeviceTargetBoundary}`]: {
          borderRadius: '$8',
          top: '-4px',
          right: '-6px',
          bottom: '-4px',
          left: '-6px',
        },
      },
      small: {
        hStack: '$4',
        display: 'inline-flex',

        [`${DeviceTargetBoundary}`]: {
          borderRadius: '$6',
          top: '-2px',
          right: '-4px',
          bottom: '-2px',
          left: '-4px',
        },
      },
    },
    isActive: {
      true: {
        [`${DeviceTargetBoundary}`]: {
          backgroundColor: colors.bgBrandLight,
          strokeAll: colors.strokeBrandLight,
          [darkThemeSelector]: {
            backgroundColor: colors.bgBrandDark,
            strokeAll: colors.strokeBrandDark,
          },
        },
      },
    },
  },
});

const getDeviceTargetIconSize = (size: DeviceTargetPropSize) => {
  switch (size) {
    case 'large':
      return 20;
    default:
      return 16;
  }
};

const getDeviceTargetIcon = (size: DeviceTargetPropSize, type: DeviceTargetPropType) => {
  switch (type) {
    case 'access-point':
      return (
        <DeviceTargetIconHardware
          variant="simple"
          hardware="access-point"
          size={getDeviceTargetIconSize(size)}
        />
      );
    case 'pdu':
      return (
        <DeviceTargetIconHardware
          variant="simple"
          hardware="pdu"
          size={getDeviceTargetIconSize(size)}
        />
      );
    case 'security-appliance':
      return (
        <DeviceTargetIconHardware
          variant="simple"
          hardware="security-appliance"
          size={getDeviceTargetIconSize(size)}
        />
      );
    case 'switch':
      return (
        <DeviceTargetIconHardware
          variant="simple"
          hardware="switch"
          size={getDeviceTargetIconSize(size)}
        />
      );
    case 'location':
    case 'locations':
    case 'port-forward':
    case 'rack':
    case 'radius':
    case 'ssid':
    case 'virtual-device':
    case 'vlan':
    case 'wifi':
      return (
        <DeviceTargetIconResource
          icon={type}
          size={getDeviceTargetIconSize(size)}
          color={{ dark: 'white' }}
        />
      );
    default:
      return <DeviceTargetIconManufacturer icon={type} size={getDeviceTargetIconSize(size)} />;
  }
};

export const DeviceTarget = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      as = 'a' as Tag,
      children,
      type,
      onClick,
      size = 'medium',
      wrap = true,
      isActive = false,
      ...remaining
    }: PolymorphicComponentProps<Tag, DeviceTargetProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => (
    <DeviceTargetContainer
      as={as}
      ref={forwardedRef}
      tabIndex={0}
      role={onClick && 'button'}
      onClick={onClick}
      size={size}
      isActive={isActive}
      {...remaining}
    >
      {type && getDeviceTargetIcon(size, type)}
      <DeviceTargetLabel size={size} wrap={wrap}>
        {children}
      </DeviceTargetLabel>
      <DeviceTargetBoundary />
    </DeviceTargetContainer>
  ),
) as Polymorphic.ForwardRefComponent<'a', DeviceTargetProps>;
