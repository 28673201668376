import type {
  Cell,
  Column,
  ColumnDef,
  OnChangeFn,
  Row,
  RowData,
  RowSelectionState,
  SortingState,
  Table as ReactTable,
} from '@tanstack/react-table';
import type { Dispatch, ForwardedRef, SetStateAction } from 'react';
import type { To } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableCellBuffer,
  TableCellStateLeading,
  TableCellStateTrailing,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  Tooltip,
} from '@meterup/atto';
import { rankItem } from '@tanstack/match-sorter-utils';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import React, { memo, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { isDefined } from '../helpers/isDefined';

export type { ColumnDef, SortingState };
export { createColumnHelper };

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-shadow
  interface ColumnMeta<TData extends RowData, TValue> {
    isLeading?: boolean;
    alignment?: 'start' | 'center' | 'end';
    condense?: boolean;
    hideSortIcon?: boolean;
    minBreakpoint?: 'sm' | 'md' | 'lg' | 'xl';
    tooltip?: { contents: string };
    internal?: boolean;
    width?: number;
    maxWidth?: number;
    minWidth?: number;
  }
}

// Based on https://tanstack.com/table/v8/docs/api/features/filters#filter-meta
function globalFilterFn<D extends any>(
  row: Row<D>,
  columnId: string,
  filterValue: string,
  addMeta: (meta: any) => void,
) {
  // make dots, dashes, colons, and underscores interchangeable
  const pattern = filterValue.replace(/[.\-_:]/g, '');

  const itemRank = rankItem(row.getValue(columnId), pattern);

  addMeta(itemRank);

  return itemRank.passed;
}

const getSizeProps = (_: ReactTable<any>, column: Column<any>) => {
  const meta = column.columnDef.meta ?? {};
  const { alignment = 'start', condense, hideSortIcon, width, maxWidth, minWidth } = meta;

  return {
    alignment,
    condense,
    hideSortIcon,
    style: {
      width: maxWidth || width,
      minWidth: minWidth || width,
    },
  };
};

type GetLinkTo<D extends any> = (row: D) => To | null | undefined;
type IsRowSelected<D> = (row: D) => boolean;
type OnRowDeselect<D> = (row: D) => void;
type OnRowClick<D> = (row: D) => void;

type SubAutoTableProps<D> = {
  data: D;
  isNested: boolean;
};

function AutoTableCellImpl<D extends any>({
  as,
  cell,
  table,
  isNavigable,
  isNested,
  to,
  ...remaining
}: {
  as?: React.ElementType;
  table: ReactTable<D>;
  cell: Cell<D, any>;
  isMultiSelected?: boolean;
  isNavigable?: boolean;
  isNested?: boolean;
  to?: To;
}) {
  const content = flexRender(cell.column.columnDef.cell, cell.getContext());
  return (
    <TableCell
      key={cell.id}
      {...getSizeProps(table, cell.column)}
      isLeading={cell.column.columnDef.meta?.isLeading}
      isNavigable={isNavigable}
      isNested={isNested}
      internal={cell.column.columnDef.meta?.internal}
      as={cell.column.columnDef.meta?.isLeading ? as : undefined}
      to={cell.column.columnDef.meta?.isLeading ? to : undefined}
      {...remaining}
    >
      {content}
    </TableCell>
  );
}

const AutoTableCell = memo(AutoTableCellImpl) as typeof AutoTableCellImpl;

interface AutoTableRowProps<D extends any> {
  table: ReactTable<D>;
  row: Row<D>;
  getLinkTo?: GetLinkTo<D>;
  isRowSelected?: IsRowSelected<D>;
  onRowClick?: OnRowClick<D>;
  isMultiSelected?: boolean;
  isNested?: boolean;
  subTableRender?: (props: SubAutoTableProps<D>) => JSX.Element;
  collapsed?: boolean;
}

function AutoTableRowImpl<D extends any>({
  table,
  row,
  getLinkTo,
  isRowSelected,
  onRowClick,
  isMultiSelected,
  isNested,
  collapsed: parentCollapsed,
  subTableRender,
}: AutoTableRowProps<D>) {
  const to = getLinkTo?.(row.original);
  const isSelected = isRowSelected?.(row.original) ?? false;

  const handleClick = useCallback(() => {
    onRowClick?.(row.original);
  }, [onRowClick, row.original]);

  const [collapsed, setCollapsed] = useState(true);
  useEffect(() => {
    if (typeof parentCollapsed === 'boolean') {
      setCollapsed(parentCollapsed);
    }
  }, [parentCollapsed]);

  const subRowData = row.original;

  return (
    <>
      <TableRow
        key={row.id}
        isSelected={!!(isSelected || isMultiSelected)}
        isNested={isNested}
        onClick={handleClick}
      >
        {(!isNested || subTableRender) && <TableCellBuffer />}
        <TableCellStateLeading />
        {subTableRender && (
          <TableCell collapsable onClick={() => setCollapsed(!collapsed)} collapsed={collapsed} />
        )}
        {row.getVisibleCells().map((cell) => (
          <AutoTableCell
            as={isDefined(to) ? Link : undefined}
            to={isDefined(to) ? to : undefined}
            key={cell.id}
            table={table}
            cell={cell}
            isMultiSelected={isMultiSelected}
            isNested={isNested}
          />
        ))}
        <TableCellStateTrailing />
        {(!isNested || subTableRender) && <TableCellBuffer />}
      </TableRow>
      {subTableRender && !collapsed && (
        <TableRow>
          <TableCell hasNested colSpan={1000}>
            {subTableRender({ data: subRowData, isNested: true })}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

const AutoTableRow = memo(AutoTableRowImpl) as typeof AutoTableRowImpl;

// react-table throws errors when getting row model if the sort/filter
// state is invalid. This is probably just because of the global URL-based
// search state becoming outdated, so we try resetting the filter and sort
// state and getting the rows again once.
const getRows = <D,>(table: ReactTable<D>): Row<D>[] => {
  try {
    return table.getRowModel()?.rows ?? [];
  } catch (err) {
    table.resetSorting();
    table.resetGlobalFilter();
    table.resetColumnFilters();
    return table.getRowModel()?.rows ?? [];
  }
};

function AutoTableBodyImpl<D extends any>({
  actions,
  table,
  getLinkTo,
  isRowSelected,
  onRowClick,
  multiSelectedRows,
  isNested,
  subTableRender,
  collapsed,
}: {
  actions?: React.ReactNode;
  table: ReactTable<D>;
  getLinkTo?: GetLinkTo<D>;
  isRowSelected?: IsRowSelected<D>;
  onRowClick?: OnRowClick<D>;
  multiSelectedRows?: RowSelectionState;
  isNested?: boolean;
  subTableRender?: (props: SubAutoTableProps<D>) => JSX.Element;
  collapsed?: boolean;
}) {
  const rows = getRows(table);

  return (
    <TableBody>
      {rows.map((row) => (
        <AutoTableRow
          key={row.id}
          row={row}
          table={table}
          isRowSelected={isRowSelected}
          isNested={isNested}
          collapsed={collapsed}
          getLinkTo={getLinkTo}
          onRowClick={onRowClick}
          isMultiSelected={multiSelectedRows?.[row.id]}
          subTableRender={subTableRender}
        />
      ))}
      {actions && (
        <TableRow>
          {!isNested && <TableCellBuffer />}
          <TableCellStateLeading />
          <TableCell colSpan={1000}>{actions}</TableCell>
          <TableCellStateTrailing />
          {!isNested && <TableCellBuffer />}
        </TableRow>
      )}
    </TableBody>
  );
}

interface AutoTableProps<D> {
  actions?: React.ReactNode;
  globalFilter?: string;
  sortingState?: SortingState;
  onChangeSortingState?: Dispatch<SetStateAction<SortingState>>;
  data: D[];
  columns: ColumnDef<D, any>[];
  getLinkTo?: GetLinkTo<D>;
  isRowSelected?: IsRowSelected<D>;
  onRowDeselect?: OnRowDeselect<D>;
  enableSorting?: boolean;
  enableRowSelection?: boolean;
  enableMultiRowSelection?: boolean;
  /**
   * Does not need to end with `.csv`, it will be added automatically.
   */
  exportToCSVFilename?: string;
  onRowClick?: OnRowClick<D>;
  multiSelectedRows?: RowSelectionState;
  onRowMultiSelectionChange?: OnChangeFn<RowSelectionState>;
  getRowId?: (originalRow: D, index: number, parent?: Row<D>) => string;
  size?: 'auto' | 'small' | 'large';
  isNested?: boolean;
  subTableRender?: (props: SubAutoTableProps<D>) => JSX.Element;
  subTableCollapsedByDefault?: boolean;
}

export interface ExportableToCSV {
  exportToCSV: () => void;
}

function AutoTableInner<D>(
  {
    actions,
    sortingState,
    globalFilter,
    columns,
    data,
    getLinkTo,
    isRowSelected,
    onChangeSortingState,
    onRowClick,
    enableSorting = true,
    enableMultiRowSelection = false,
    exportToCSVFilename = 'export',
    onRowMultiSelectionChange,
    multiSelectedRows = {},
    getRowId,
    isNested,
    subTableRender,
    subTableCollapsedByDefault = true,
  }: AutoTableProps<D>,
  ref: ForwardedRef<ExportableToCSV>,
) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: enableSorting ? getSortedRowModel() : undefined,
    enableGlobalFilter: true,
    globalFilterFn,
    onSortingChange: onChangeSortingState,
    enableMultiRowSelection,
    onRowSelectionChange: onRowMultiSelectionChange,
    getRowId,
    state: {
      globalFilter,
      sorting: sortingState,
      rowSelection: multiSelectedRows,
    },
  });

  const csvConfig = useMemo(() => {
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: true,
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename: exportToCSVFilename.replace(/\.csv$/, ''),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    return mkConfig(csvOptions);
  }, [exportToCSVFilename]);

  const handleExportToCSV = useCallback(() => {
    const { rows } = table.getRowModel();
    const csvData = rows.map((row) =>
      Object.fromEntries(
        row.getAllCells().map((cell) => [cell.column.columnDef.header ?? '', cell.getValue()]),
      ),
    );

    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig)(csv);
  }, [csvConfig, table]);

  useImperativeHandle(ref, () => ({
    exportToCSV: handleExportToCSV,
  }));

  const hasSubTable = subTableRender;
  const [collapsed, setCollapsed] = useState(subTableCollapsedByDefault);

  return (
    <Table isNested={isNested}>
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableHeadRow key={headerGroup.id}>
            {(!isNested || hasSubTable) && <TableCellBuffer head />}
            <TableCellStateLeading head />
            {hasSubTable && (
              <TableHeadCell
                collapsable
                onClick={() => setCollapsed(!collapsed)}
                collapsed={collapsed}
              />
            )}
            {headerGroup.headers.map((header) => {
              const showToolTip = header.column.columnDef.meta?.tooltip !== undefined;

              const headCell = (
                <TableHeadCell
                  {...(showToolTip ? {} : { key: header.id })}
                  sortDirection={header.column.getIsSorted()}
                  onClick={header.column.getToggleSortingHandler()}
                  {...getSizeProps(table, header.column)}
                  internal={header.column.columnDef.meta?.internal}
                  isLeading={header.column.columnDef.meta?.isLeading}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeadCell>
              );

              return showToolTip ? (
                <Tooltip
                  key={header.id}
                  contents={header.column.columnDef.meta!.tooltip!.contents}
                  side="top"
                >
                  {headCell}
                </Tooltip>
              ) : (
                headCell
              );
            })}
            <TableCellStateTrailing head />
            {(!isNested || hasSubTable) && <TableCellBuffer head />}
          </TableHeadRow>
        ))}
      </TableHead>
      <AutoTableBodyImpl
        actions={actions}
        collapsed={collapsed}
        table={table}
        getLinkTo={getLinkTo}
        isRowSelected={isRowSelected}
        isNested={isNested}
        subTableRender={subTableRender}
        onRowClick={onRowClick}
        multiSelectedRows={multiSelectedRows}
      />
    </Table>
  );
}

// Must add type assertion to carry through the generic argument to AutoTableProps
// https://fettblog.eu/typescript-react-generic-forward-refs/#option-1%3A-type-assertion
export const AutoTable = React.forwardRef(AutoTableInner) as <D extends any>(
  props: AutoTableProps<D> & { ref?: ForwardedRef<ExportableToCSV> },
) => ReturnType<typeof AutoTableInner>;
