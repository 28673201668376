import 'graphiql/graphiql.css';
import '@graphiql/plugin-explorer/dist/style.css';

import { explorerPlugin } from '@graphiql/plugin-explorer';
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import { APIProxyBaseURL, axiosInstance } from '@meterup/common';
import { GraphiQL } from 'graphiql';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const graphQLURL = `${APIProxyBaseURL}/v2/graphql`;

const parameters: Record<string, string> = {};
for (const entry of window.location.search.slice(1).split('&')) {
  const eq = entry.indexOf('=');
  if (eq >= 0) {
    parameters[decodeURIComponent(entry.slice(0, eq).replace(/\+/g, ' '))] = decodeURIComponent(
      entry.slice(eq + 1).replace(/\+/g, ' '),
    );
  }
}
function updateURL() {
  const newSearch = Object.entries(parameters)
    .filter(([, /* _ */ value]) => value)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  window.history.replaceState(null, '', `?${newSearch}`);
}
function fetchWithCreds(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
  let url: URL;
  if (typeof input === 'object' && input instanceof URL) {
    url = input;
  } else if (typeof input === 'string') {
    url = new URL(input);
  } else {
    url = new URL(input.url);
  }
  let credentials: RequestCredentials = 'same-origin';
  if (url.origin === import.meta.env.REACT_APP_API_URL) {
    credentials = 'include';
  }
  const initParams = {
    ...init,
    credentials,
  };

  updateURL();

  return fetch(input, initParams);
}
const fetcher = createGraphiQLFetcher({ fetch: fetchWithCreds, url: graphQLURL });

function onEditQuery(newQuery: string) {
  parameters.query = newQuery;
}

function onEditVariables(newVariables: string) {
  parameters.variables = newVariables;
}

function onEditHeaders(newHeaders: string) {
  parameters.headers = newHeaders;
}

function redirect() {
  window.location.href = import.meta.env.REACT_APP_PORTAL_REDIRECT_URL;
}

function MeterGraphiQL() {
  const explorer = explorerPlugin({
    showAttribution: false,
  });
  useEffect(() => {
    axiosInstance
      .get('/v1/identity')
      .then((value) => {
        if (value.status !== 200) {
          redirect();
        }
      })
      .catch(redirect);
  }, []);
  return (
    <GraphiQL
      fetcher={fetcher}
      query={parameters.query}
      variables={parameters.variables}
      headers={parameters.headers}
      onEditQuery={onEditQuery}
      onEditVariables={onEditVariables}
      onEditHeaders={onEditHeaders}
      plugins={[explorer]}
    />
  );
}

ReactDOM.render(
  <React.StrictMode>
    <MeterGraphiQL />
  </React.StrictMode>,
  document.getElementById('root'),
);
