import type { AriaCheckboxProps } from '@react-types/checkbox';
import React, { forwardRef, useRef } from 'react';
import { useCheckbox } from 'react-aria';
import { useToggleState } from 'react-stately';

import type { IconName } from '../../assets/Icon/Icon';
import type { RenameKeys } from '../../types/rename_keys';
import type { Simplify } from '../../types/simplify';
import { Icon } from '../../assets/Icon/Icon';
import { VisuallyHidden } from '../../common/visually_hidden';
import { selectors, transitions } from '../../controls/shared/styles';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { Text } from '../../text/Text';

type ToggleInputSize = 'small' | 'large';

export type ToggleInputProps = Simplify<
  RenameKeys<
    AriaCheckboxProps,
    {
      /**
       * Set if the component is selected.
       */
      isSelected: 'selected';
      /**
       * Set if the component is disabled.
       */
      isDisabled: 'disabled';
      /**
       * Set if the component is in an indeterminate state. Overrides `selected` if true.
       */
      isIndeterminate: 'indeterminate';
    }
  >
> & {
  /**
   * Set the size utilizing the [`controlSize` API](/?path=/docs/handlers-providers--use-control-size).
   */
  controlSize?: ToggleInputSize;
  disabled?: boolean;
  variant?: 'standard' | 'polarity' | 'positive';
  negative?: {
    icon?: IconName;
    label?: React.ReactNode;
  };
  neutral?: {
    icon?: IconName;
    label?: React.ReactNode;
  };
  positive?: {
    icon?: IconName;
    label?: React.ReactNode;
  };
};

const ToggleInputTabIcon = styled(Icon, {
  display: 'flex',
  variants: {
    active: {
      true: {},
      false: {},
    },
    variant: {
      standard: {},
      polarity: {},
      positive: {},
    },
    size: {
      small: {
        width: '$8',
        height: '$8',
      },
      large: {
        width: '$10',
        height: '$10',
      },
    },
  },
  compoundVariants: [
    {
      active: true,
      variant: 'polarity',
      css: {
        color: colors.iconPositiveLight,
      },
    },
    {
      active: true,
      variant: 'positive',
      css: {
        color: colors.iconPositiveLight,
      },
    },
    {
      active: false,
      variant: 'polarity',
      css: {
        color: colors.iconNegativeLight,
      },
    },
    {
      active: false,
      variant: 'positive',
      css: {
        color: colors.iconNeutralLight,
      },
    },
  ],
});

const ToggleInputTab = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '99em',
  backgroundColor: colors.white,
  boxShadow: shadows.controlRaisedInitialLight,
  pointerEvents: 'none',
  transition: transitions.control,

  [selectors.hover]: {
    boxShadow: shadows.controlRaisedHoveredLight,
  },

  [darkThemeSelector]: {
    boxShadow: shadows.controlRaisedInitialDark,

    [selectors.hover]: {
      boxShadow: shadows.controlRaisedHoveredDark,
    },
  },
  variants: {
    active: {
      true: {
        translateX: '12px',
      },
    },
    indeterminate: {
      true: {
        translateX: '5px',
        alignItems: 'center',
        justifyContent: 'center',

        '&:before': {
          content: '',
          position: 'absolute',
          display: 'block',
          overflow: 'hidden',
          background: colors.controlStrokeBaseLight,
          borderRadius: '$2',
        },
      },
    },
    size: {
      small: {
        width: '$12',
        height: '$12',

        '&:before': {
          width: '6px',
          height: '1px',
        },
      },
      large: {
        width: '$14',
        height: '$14',

        '&::before': {
          width: '7px',
          height: '1px',
        },
      },
    },
  },
  compoundVariants: [
    {
      active: true,
      size: 'small',
      css: {
        translateX: '12px',
      },
    },
    {
      active: true,
      size: 'large',
      css: {
        translateX: '10px',
      },
    },
  ],
});

const ToggleInputTrack = styled('div', {
  display: 'inline-flex',
  flex: 'none',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: colors.gray100,
  borderRadius: '99em',
  transition: transitions.control,

  [selectors.focus]: {
    boxShadow: shadows.focusRingLight,
  },

  [darkThemeSelector]: {
    backgroundColor: colors.gray600,

    [selectors.focus]: {
      boxShadow: shadows.focusRingDark,
    },
  },
  variants: {
    active: {
      true: {
        backgroundColor: colors.brand600,

        [darkThemeSelector]: {
          backgroundColor: colors.brand600,
        },
      },
    },
    indeterminate: {
      true: {
        backgroundColor: colors.gray100,

        [darkThemeSelector]: {
          backgroundColor: colors.gray600,
        },
      },
    },
    disabled: {
      true: {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
    size: {
      small: {
        width: '$28',
        height: '$16',
        padding: '0 $2',
      },
      large: {
        width: '$32',
        height: '$20',
        padding: '0 $4',
      },
    },
    variant: {
      standard: {},
      polarity: {},
      positive: {},
    },
  },
  compoundVariants: [
    {
      active: true,
      variant: 'positive',
      css: {
        backgroundColor: colors.green600,

        [darkThemeSelector]: {
          backgroundColor: colors.green600,
        },
      },
    },
    {
      active: true,
      variant: 'polarity',
      css: {
        backgroundColor: colors.green600,

        [darkThemeSelector]: {
          backgroundColor: colors.green600,
        },
      },
    },
    {
      active: false,
      variant: 'polarity',
      css: {
        backgroundColor: colors.red600,

        [darkThemeSelector]: {
          backgroundColor: colors.red600,
        },
      },
    },
  ],
});

const ToggleInputEnds = styled(Text, {
  userSelect: 'none',
  color: colors.headingNeutralLight,

  [darkThemeSelector]: {
    color: colors.headingNeutralDark,
  },

  variants: {
    size: {
      small: {
        fontSize: '$12',
        lineHeight: '$16',
      },
      large: {
        fontSize: '$14',
        lineHeight: '$20',
      },
    },
  },
});

export const ToggleInputContainer = styled('label', {
  display: 'flex',
  alignItems: 'center',
  gap: '$6',
  cursor: 'pointer',
  variants: {
    disabled: {
      true: {
        cursor: 'not-allowed',
      },
      false: {},
    },
  },
});

export const ToggleInput = forwardRef<HTMLLabelElement, ToggleInputProps>(
  ({ controlSize = 'large', negative, neutral, positive, variant, ...props }, forwardedRef) => {
    const { disabled = false, selected, indeterminate } = props;
    const ariaProps = {
      ...props,
      isSelected: selected,
      isDisabled: disabled,
      isIndeterminate: indeterminate,
    };

    const ref = useRef<HTMLInputElement>(null);
    const state = useToggleState(ariaProps);
    const { inputProps } = useCheckbox(ariaProps, state, ref);

    const isSelected = state.isSelected && !indeterminate;

    return (
      <ToggleInputContainer disabled={disabled} ref={forwardedRef}>
        {(variant === 'positive' || variant === 'polarity') &&
          (neutral?.label || negative?.label) && (
            <ToggleInputEnds size={controlSize} weight="bold">
              {neutral?.label || negative?.label}
            </ToggleInputEnds>
          )}
        <ToggleInputTrack
          active={isSelected}
          disabled={disabled}
          size={controlSize}
          role="checkbox"
          aria-checked={isSelected}
          indeterminate={indeterminate}
          variant={variant}
        >
          <VisuallyHidden>
            <input {...inputProps} ref={ref} />
          </VisuallyHidden>
          <ToggleInputTab
            aria-hidden="true"
            active={isSelected}
            size={controlSize}
            indeterminate={indeterminate}
          >
            {(variant === 'polarity' || variant === 'positive') && (
              <ToggleInputTabIcon
                variant={variant}
                active={isSelected}
                icon={
                  isSelected
                    ? positive?.icon || 'checkmark'
                    : neutral?.icon || negative?.icon || 'cross'
                }
                size={controlSize}
              />
            )}
          </ToggleInputTab>
        </ToggleInputTrack>
        {(variant === 'positive' || variant === 'polarity') && positive?.label && (
          <ToggleInputEnds size={controlSize} weight="bold">
            {positive.label}
          </ToggleInputEnds>
        )}
      </ToggleInputContainer>
    );
  },
);
