import React, { type ComponentProps } from 'react';

import { sizing } from '../../common/sizing';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { Large } from '../../text/Large';
import { Small } from '../../text/Small';

const StatDisplay = styled(Large, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  height: '100%',

  variants: {
    alignment: {
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
      start: {
        justifyContent: 'flex-start',
      },
    },
  },
});

const StatLabel = styled(Small, {
  width: '100%',
  fontWeight: fontWeights.bold,

  variants: {
    variant: {
      negative: {
        color: colors.bodyNegativeLight,

        [darkThemeSelector]: {
          color: colors.bodyNegativeDark,
        },
      },
      neutral: {},
      positive: {
        color: colors.bodyPositiveLight,

        [darkThemeSelector]: {
          color: colors.bodyPositiveDark,
        },
      },
    },
  },
});

const StatLegend = styled(Body, {
  display: 'flex',
  flexDirection: 'row',
});

const StatHeader = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '$8',
});

const StatInner = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '$4',
  padding: '$8',

  variants: {
    alignment: {
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'flex-end',
      },
      start: {
        alignItems: 'flex-start',
      },
    },
    arrangement: {
      'leading-display': {
        flexDirection: 'column-reverse',
      },
      'leading-label': {
        flexDirection: 'column',
      },
    },
  },
});

const StatContainer = styled('div', {
  position: 'relative',
  height: '100%',

  '&::before, &::after': {
    content: '',
    position: 'absolute',
    backgroundColor: colors.strokeNeutralLight,
    zIndex: 1,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },
  },

  '&::after': {
    inlineSize: '100vw',
    blockSize: '1px',
    insetInlineStart: 0,
    insetBlockStart: `calc(calc(12px / 2) * -1)`,
  },

  '&::before': {
    inlineSize: '1px',
    blockSize: '100vh',
    insetInlineStart: `calc(calc(12px / 2) * -1)`,
    insetBlockStart: 0,
  },
});

type StatAlignmentProp = 'center' | 'end' | 'start';
type StatArrangementProp = 'leading-display' | 'leading-label';

type StatProps = {
  alignment?: StatAlignmentProp;
  arrangement?: StatArrangementProp;
  display: React.ReactNode;
  label: React.ReactNode;
  legend?: React.ReactNode;
  variant?: 'negative' | 'neutral' | 'positive';
} & ComponentProps<typeof StatContainer>;

function Stat({
  alignment,
  arrangement,
  display,
  label,
  legend,
  variant,
  ...remaining
}: StatProps) {
  return (
    <StatContainer {...remaining}>
      <StatInner alignment={alignment} arrangement={arrangement}>
        <StatHeader>
          <StatLabel variant={variant}>{label}</StatLabel>
          {legend && <StatLegend>{legend}</StatLegend>}
        </StatHeader>
        <StatDisplay alignment={alignment}>{display}</StatDisplay>
      </StatInner>
    </StatContainer>
  );
}

const StatsInner = styled('div', {
  position: 'relative',
  display: 'grid',
  gridTemplateRows: 'repeat(auto-fit, minmax(0, min-content))',
  gap: '$12',
  width: '100%',
  overflow: 'hidden',
});

const StatsContainer = styled('div', {
  display: 'flex',
  width: '100%',
  padding: sizing.contentSquish,
});

export type StatsProps = {
  alignment?: StatAlignmentProp;
  arrangement?: StatArrangementProp;
  maxWidth?: string;
  minWidth?: string;
  stats: StatProps[];
} & ComponentProps<typeof StatsContainer>;

export function Stats({
  alignment = 'start',
  arrangement = 'leading-label',
  maxWidth = '1fr',
  minWidth = '120px',
  stats,
  ...remaining
}: StatsProps) {
  return (
    <StatsContainer {...remaining}>
      <StatsInner
        style={{ gridTemplateColumns: `repeat(auto-fit, minmax(${minWidth}, ${maxWidth}))` }}
      >
        {stats.map((stat) => (
          <Stat key={`${stat.label}`} alignment={alignment} arrangement={arrangement} {...stat} />
        ))}
      </StatsInner>
    </StatsContainer>
  );
}
